import i18next from 'i18next';

i18next
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    lng: getLanguage(), // 'en' | 'es'
    // Using simple hardcoded resources for simple example
    resources: {
      en: {
        translation: {
            month: {
                label: 'Month',
                January: 'January',
                February: 'February',
                March: 'March',
                April: 'April',
                May: 'May',
                June: 'June',
                July: 'July',
                August: 'August',
                September: 'September',
                October: 'October',
                November: 'November',
                December: 'December'

            },
            surveys: {
                conducted: 'surveys conducted'
            },
            other: {
                calls: 'Calls done',
                call: 'calls',
                qc: 'answers',
                surveys_sended: 'emails sended',
                surveys_respond: 'emails respond',
                visits: 'visits',
                view_more: 'View more',
                period: 'Visits resume',
                telephone: 'Answered calls',
                email: 'E-mails sent',
                responses: 'E-mails responded',
                contacted: '% Visits contacted',
                rate: '% Answers over contacted',
                total: '% Answers over total visits',
                reporting: 'Reporting period',
                unanswered: 'Sent without response',
                answers: 'Answers',
                shop_unanswered: 'Shops not respond',
                code: 'Code',
                name: 'Name',
                not_respond: 'Not Respond',
                valoration: 'Valoration',
                comment: 'Comment',
                previous: 'Previous',
                next: 'Next',
                open_inc: 'Open incidences',
                inc_close: 'Closed incidences',
                timing: 'Average time',
                congratulations: 'Compliments'
            },
            answer: {
              first: 'What overall score would you give us?',
              second: 'Rate the speed of our service',
              third: 'Appreciates the friendliness of our technicians',
              fourth: 'Scores the resolution capacity of the incidences',
              five: 'What aspects can we improve in order to obtain the highest score?'
            },
            quality: {
              control: 'Incidence detected and managed by the quality department'
            }
        },
      },
      es: {
        translation: {
            month: {
                label: 'Mes',
                January: 'Enero',
                February: 'Febrero',
                March: 'Marzo',
                April: 'Abril',
                May: 'Mayo',
                June: 'Junio',
                July: 'Julio',
                August: 'Agosto',
                September: 'Septiembre',
                October: 'Octubre',
                November: 'November',
                December: 'December'
            },
            surveys: {
                conducted: 'Encuestas realizadas'
            },
            other: {
                calls: 'LLamadas realizadas',
                call: 'calls',
                qc: 'answers',
                surveys_sended: 'emails enviados',
                surveys_respond: 'emails respondidos',
                visits: 'visitas',
                view_more: 'Ver más',
                period: 'Total de visitas realizadas',
                telephone: 'Llamadas contestadas',
                email: 'Correos enviados',
                responses: 'Correos respondidos',
                contacted: '% Visitas contactadas',
                rate: '% Respuestas sobre contactadas',
                total: '% Respuestas sobre el total de visitas',
                reporting: 'Periodo de reporte',
                unanswered: 'Enviados sin respuesta',
                answers: 'Respuestas',
                shop_unanswered: 'Tiendas sin respuesta',
                code: 'Código',
                name: 'Nombre',
                not_respond: 'No Responde',
                valoration: 'Valoración',
                comment: 'Comentario',
                previous: 'Anterior',
                next: 'Siguiente',
                open_inc: 'Incidencias abiertas',
                inc_close: 'Incidencias cerradas',
                timing: 'Tiempo promedio',
                congratulations: 'Felicitaciones'
            },
            answer: {
              first: '¿Qué puntuación global nos darías?',
              second: 'Valoración de la rapidez de nuestro servicio',
              third: 'Valoración de la amabilidad de nuestros técnicos',
              fourth: 'Valoración de la capacidad de resolver incidencias',
              five: '¿Qué aspectos podemos mejorar para obtener la máxima puntuación?'
            },
            quality: {
              control: 'Incidencia detectada y gestionada por el dept de calidad'
            }
        },
      },
      pt: {
        translation: {
            month: {
                label: 'Mes',
                January: 'Enero',
                February: 'Febrero',
                March: 'Marzo',
                April: 'Abril',
                May: 'Mayo',
                June: 'Junio',
                July: 'Julio',
                August: 'Agosto',
                September: 'Septiembre',
                October: 'Octubre',
                November: 'November',
                December: 'December'
            },
            surveys: {
                conducted: 'Inquéritos realizados'
            },
            other: {
                calls: 'Ligações efetuadas',
                call: 'calls',
                qc: 'answers',
                surveys_sended: 'emails enviados',
                surveys_respond: 'emails respondidos',
                visits: 'visitas',
                view_more: 'Ver mais',
                period: 'Total visitas efetuadas',
                telephone: 'Ligações atendidas',
                email: 'E-mail enviados',
                responses: 'E-mails respondidos',
                contacted: '% Visitas contactadas',
                rate: '% Respostas sobre contactado',
                total: '%Respostas sobre o total de visitas',
                reporting: 'Período do relátorio',
                unanswered: 'Enviado sem resposta',
                answers: 'Respostas',
                shop_unanswered: 'Tiendas sin respuesta',
                code: 'Código',
                name: 'Nombre',
                not_respond: 'No Responde',
                valoration: 'Qualificaçao',
                comment: 'Comentário',
                previous: 'Anterior',
                next: 'Próximo',
                open_inc: 'Incidências abertas',
                inc_close: 'Icidentes fechadas',
                timing: 'Tiempo médio',
                congratulations: 'Cumprimientos'
            },
            answer: {
              first: 'Que nota geral nos daría?',
              second: 'Avalie a velocidade do nosso serviço',
              third: 'Avalie a simpatia dos nossos técnicos',
              fourth: 'Avalie a capacidade de resolver incidentes',
              five: 'Que aspectos podemos melhorar para obter a puntuaçao máxima?'
            },
            quality: {
              control: 'Incidencia detectada y gestionada por el dept de calidad'
            }
        },
      },
    },
  })

function getLanguage() {
    let acceptLanguage = ['es','en','pt'];
    let language = window.navigator.language.slice(0,window.navigator.language.indexOf('-'));
    return (acceptLanguage.indexOf(language) > -1) ? language : 'en';
}

export default i18next